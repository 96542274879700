import * as React from 'react';
import { ILinksRightData } from '../watersportdiscounts-header.props.autogenerated';

export interface IContentCardLinksRight {
    linksRight: ILinksRightData[];
}

const ContentCardLinksRight: React.FC<IContentCardLinksRight> = ({ linksRight }) => (
    <div className='linksRight'>
        {linksRight.map((cta: ILinksRightData, index: number) => {
            
            return <a
                key={`link-${index}`}
                className='flex-vertical'
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                <span>{cta.linkText}</span>
            </a>;
        })}
    </div>
);

export default ContentCardLinksRight;