import * as React from 'react';
import { ILinksLeftData } from '../watersportdiscounts-header.props.autogenerated';

export interface IContentCardLinksLeft {
    linksLeft: ILinksLeftData[];
}

const ContentCardLinksLeft: React.FC<IContentCardLinksLeft> = ({ linksLeft }) => (
    <div className='linksLeft'>
        {linksLeft.map((cta: ILinksLeftData, index: number) => {
            
            return <a
                key={`link-${index}`}
                className='flex-vertical'
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                <span>{cta.linkText}</span>
            </a>;
        })}
    </div>
);

export default ContentCardLinksLeft;